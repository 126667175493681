import React, { useEffect } from 'react';
import { NavLink } from "@/components/ui";
import { IPageMenu, IPageSubMenu } from "@/interfaces/IPages";
import styles from './Header.module.css';

type Props = {
    HeaderData: IPageMenu[]
}

export function HeaderChilds({ HeaderData }: Props) {
    useEffect(() => {
        HeaderData && HeaderData.length > 0 && HeaderData.forEach((menu) => {
            if (menu.child && menu.child.length > 0) {
                const targetDiv = document.getElementById(menu.pageName.textToSlug());
                const content = document.getElementById(`submenu-${menu.pageName.textToSlug()}`);
                if (targetDiv && content) {
                    targetDiv.appendChild(content);
                }
            }
        });
    }, [HeaderData]);

    const generateColumns = (children: IPageSubMenu[]) => {
        if (!children) return [];
        if (children.length <= 8) return [children];
        const columnCount = Math.ceil(children.length / 8);
        const columns = Array.from({ length: columnCount }, (_, i) =>
            children.slice(i * 8, (i + 1) * 8)
        );
        return columns;
    };

    return (
        HeaderData.map((menu, menuIdx) => (
            menu.child && menu.child.length > 0 && (
                <div
                    key={menuIdx}
                    id={`submenu-${menu.pageName.textToSlug()}`}
                    className={styles.subDropdown}
                >
                    {generateColumns(menu.child).map((column, columnIdx) => (
                        <ul key={columnIdx}>
                            {column.map((subMenu, subMenuIdx) => (
                                <li key={subMenuIdx}>
                                    <NavLink href={subMenu.pageUrl}>
                                        {subMenu.pageName === "Gurgaon" ? "Gurugram" : subMenu.pageName}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    ))}
                </div>
            )
        ))
    );
}